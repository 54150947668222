export default {
	//获取url的指定参数
	getUrlKey(name) {
	  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
	},
	// 根据文件名后缀区分 文件类型
	matchType(fileName) {
		// 后缀获取
		var suffix = '';
		// 获取类型结果
		var result = '';
		try {
			var flieArr = fileName.split('.');
			suffix = flieArr[flieArr.length - 1];
		} catch (err) {
			suffix = '';
		}
		// fileName无后缀返回 false
		if (!suffix) {
			result = false;
			return result;
		}
		// 图片格式
		var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
		// 进行图片匹配
		result = imglist.some(function(item) {
			return item == suffix;
		});
		if (result) {
			result = 'image';
			return result;
		};
		// 匹配 视频
		var videolist = ['mp4', 'm2v', 'mkv'];
		result = videolist.some(function(item) {
			return item == suffix;
		});
		if (result) {
			result = 'video';
			return result;
		};
		// 其他 文件类型
		result = 'other';
		return result;
	},
	//判断Windows 移动端
	isMobile() {
		let flag = navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		);
		let goUrl = flag ? 1 : 0;
		return goUrl;
	},
	
	//递归处理数据
	//lst 数据
	//pid 父级ID
	listToTree(lst, pid) {
		const that = this;
		let tmp = [];
		let treeLst = lst;
		treeLst.forEach((item, index) => {
	    if (item.pId === pid) {
			const children = that.listToTree(treeLst, item.id)
			if (children && (children.length > 0)) {
				treeLst[index].children = children;
			}
			tmp.push(treeLst[index]);
	    }
		})
	  return tmp;
	},
	
	// 数组去重
	unique (arr) {
		let newArr = [];
		let len = arr.length;
		for (let i = 0; i < len; i++) {
			if (newArr.indexOf(arr[i]) == -1) {
				newArr.push(arr[i]);
			}
		}
		return newArr
	},
}
