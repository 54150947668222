import Vue from 'vue'
import VueRouter from 'vue-router'
//导入
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/login',
		name: 'Login',
		component: resolve => require(['views/login/Login'], resolve),
	},
	{
		path: '/home',
		component: resolve => require(['views/Home'], resolve),
		children: [
			//动画视频
			{ path: 'animationVideo', component: resolve => require(['views/childComp/animationVideo/AnimationVideo'], resolve)},
			//工艺文件
			{ path: 'processFile', component: resolve => require(['views/childComp/processFile/ProcessFile'], resolve)},
			//产能 汇报
			{ path: 'capacityReport', component: resolve => require(['views/childComp/capacityReport/CapacityReport'], resolve)},
			//设备履历
			{ path: 'deviceResume', component: resolve => require(['views/childComp/deviceResume/DeviceResume'], resolve)},
			//设备状态
			{ path: 'deviceStatus', component: resolve => require(['views/childComp/deviceStatus/DeviceStatus'], resolve)},
			//设备保养
			{ path: 'deviceUpkeep', component: resolve => require(['views/childComp/deviceUpkeep/DeviceUpkeep'], resolve)},
			//安灯呼叫
			{ path: 'andonCall', component: resolve => require(['views/childComp/andonCall/AndonCall'], resolve)},
			//点检表
			{ path: 'tallySheet', component: resolve => require(['views/childComp/tallySheet/TallySheet'], resolve)},
			//不良表
			{ path: 'badnessSheet', component: resolve => require(['views/childComp/badnessSheet/BadnessSheet'], resolve)},
			//工时管理
			{ path: 'workHourManager', component: resolve => require(['views/childComp/workHourManager/WorkHourManager'], resolve)},
			//产品履历
			{ path: 'productResume', component: resolve => require(['views/childComp/productResume/ProductResume'], resolve)},
			//生产管理
			{ path: 'productManager', component: resolve => require(['views/childComp/productManager/ProductManager'], resolve)}
		]
	},
]

const router = new VueRouter({
  routes
})



router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})




export default router
