 
 const baseSize = 18;
 // const scale = document.documentElement.clientHeight / 1080;
 // 设置 rem 函数
 function setRem () {
   // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
   const scale = document.documentElement.clientWidth / 1920;
   // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
   document.documentElement.style.fontSize = baseSize * scale + 'px';
 }
 // 初始化
 setRem()
 //改变窗口大小时重新设置 rem
 window.onresize = function () {
	setRem();
 }
